import React, { Fragment, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import axios from 'axios';
import 'antd/dist/antd.css';
import 'react-resizable/css/styles.css';
import { Resizable } from 'react-resizable';
const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;
// const STORAGE_URL = "https://storage.websiteeditor.accessibilis.fr";

const ImageUploader = ({ onImageResize, resizable, initialImage, onImageChange, parentId, imageIndex, isBig, fromSidebar, onSideImageDelete, ...data }) => {
    console.log("CREATE IMAGEUPLOADER", data)

    const [fileList, setFileList] = useState(initialImage ? [{ thumbUrl: `${STORAGE_URL}/${initialImage}` }] : [])

    let _width = 200;
    let _height = 200;
    if (data.images_datas && data.images_datas[0]) {
        _width = data.images_datas[0].width;
        _height = data.images_datas[0].height;
    }
    if (data.images && data.images[0]) {
        _width = data.images[0].width;
        _height = data.images[0].height;
    }
    if (data.image) {
        _width = data.image.width;
        _height = data.image.height;
    }
    const [width, setWidth] = useState(_width);
    const [height, setHeight] = useState(_height);

    const onResize = (event, { element, size, handle }) => {
        const width = size.width < 200 ? 200 : size.width;
        const height = size.height < 200 ? 200 : size.height;
        setWidth(width);
        setHeight(height);
        onImageResize(parentId, imageIndex, width, height);
    };

    const onChange = async ({ fileList: newFileList }) => {
        console.log("ON CHANGE", newFileList)

        if (newFileList.length > 0) {
            const file = newFileList[0]
            const base64image = await getBase64(file.originFileObj);
            console.log("HANDLE PREVIEW", file)
            // setPreviewImage(file.url || file.preview)
            newFileList[0] = { thumbUrl: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Youtube_loading_symbol_1_(wobbly).gif" }
        }
        setFileList(newFileList);


        console.log('NEW FILE LIST', newFileList)
    };

    const onImageUploaded = (path) => {
        console.log("IMAGEUPLOADER > ON IMAGE UPLOADED", parentId, path)
        onImageChange(parentId, imageIndex,
            {
                url: path,
                size: isBig ? "BIG" : "SMALL"
            })
        setFileList([{ thumbUrl: `${STORAGE_URL}/${path}` }])
    }

    const uploadImage = (options) => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data" },
            // onUploadProgress: event => {
            //     const percent = Math.floor((event.loaded / event.total) * 100);
            //     setProgress(percent);
            //     if (percent === 100) {
            //         setTimeout(() => setProgress(0), 1000);
            //     }
            //     onProgress({ percent: (event.loaded / event.total) * 100 });
            // }
        };
        fmData.append("image", file);
        try {
            const res = axios
                .post(
                    STORAGE_URL + "/imageupload",
                    fmData,
                    config
                )
                .then((resp) => resp.data)
                .then((data) => {
                    onImageUploaded(data.path);
                })


            onSuccess("Ok");
            console.log("server res: ", res);
        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }
    };

    const onPreview = async (file) => {
        console.log("ON PREVIEW", file)
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };



    const getBase64 = (file) => {
        console.log("GET BASE 64");
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const deleteSideImage = () => {
        onSideImageDelete(parentId, imageIndex)
    }

    return (
        <div className={isBig ? "big" : "small"}>
            {
                resizable ?
                    <Fragment>

                        {onSideImageDelete && <div className="deleteRow" onClick={deleteSideImage}> </div>}
                        <Resizable height={height} width={width} onResize={onResize}>
                            <div className="box" style={{ padding: '5px', width: width + 'px', height: height + 'px' }}>
                                <Upload
                                    // iconRender={() => <img alt="example" className="ant-upload-list-item-image" style={{ width: '100%' }} src={previewImage} />}
                                    accept="image/*"
                                    customRequest={uploadImage}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChange}
                                // onPreview={onPreview}
                                // onPreview={handlePreview}
                                >
                                    {
                                        fileList.length < 1 &&
                                        <div className="uploadButton">
                                            <img src="/images/upload.svg" />
                                            <h3>Insérer une image</h3>
                                        </div>
                                    }
                                </Upload>
                            </div>
                        </Resizable>
                    </Fragment>
                    :
                    <Fragment>

                        {onSideImageDelete && <div className="deleteRow" onClick={deleteSideImage}> </div>}
                        <ImgCrop grid>
                            <Upload
                                accept="image/*"
                                customRequest={uploadImage}
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                            >
                                {
                                    fileList.length < 1 &&
                                    <div className="uploadButton">
                                        <img src="/images/upload.svg" />
                                        <h3>Insérer une image</h3>
                                    </div>
                                }
                            </Upload>
                        </ImgCrop>
                    </Fragment>
            }
        </div>
    );
}

export default ImageUploader;