import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import useStateRef from 'react-usestateref';
import uuid from 'uuid/v4';
// import MyEditor from './Falc/Editor/Editor';
import { submitWebsite, fetchWebsiteData } from '../actions/storage-actions';
import { getUrl, getWebsiteData } from '../reducers/storage-reducer'
import MyEditor from './Falc/Editor/Editor';
import history from '../history';
const validEmail = (mail) => {
    return (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(mail))
}

const getElementByXpath = (path, doc) => {
    return doc.evaluate(path, doc, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
}



const createXPathFromElement = (element, document) => { //https://gist.github.com/beatngu13/a3312b98de57610c5ecd27ea84a7fbeb
    var comp, comps = [];
    var parent = null;
    var xpath = '';
    var getPos = function (element) {
        var position = 1,
            curNode;
        if (element.nodeType == Node.ATTRIBUTE_NODE) {
            return null;
        }
        for (curNode = element.previousSibling; curNode; curNode = curNode.previousSibling) {
            if (curNode.nodeName == element.nodeName) {
                ++position;
            }
        }
        return position;
    };

    if (element instanceof Document) {
        return '/';
    }

    for (; element && !(element instanceof Document); element = element.nodeType == Node.ATTRIBUTE_NODE ? element.ownerElement : element.parentNode) {
        comp = comps[comps.length] = {};
        switch (element.nodeType) {
            case Node.TEXT_NODE:
                comp.name = 'text()';
                break;
            case Node.ATTRIBUTE_NODE:
                comp.name = '@' + element.nodeName;
                break;
            case Node.PROCESSING_INSTRUCTION_NODE:
                comp.name = 'processing-instruction()';
                break;
            case Node.COMMENT_NODE:
                comp.name = 'comment()';
                break;
            case Node.ELEMENT_NODE:
                comp.name = element.nodeName;
                break;
        }
        comp.position = getPos(element);
    }

    for (var i = comps.length - 1; i >= 0; i--) {
        comp = comps[i];
        if (comp.name) {
            xpath += '/' + comp.name.toLowerCase();
            if (comp.position !== null) {
                xpath += '[' + comp.position + ']';
            }
        }
    }
    return xpath;
};


const Simplified = (props) => {

    const [simplifications, setSimplifications, simplificationsRef] = useStateRef([]);
    const [endActive, setEndActive] = useState(false);
    const [emailValid, setEmailValid, emailValidRef] = useStateRef(false);
    const [email, setEmail, emailRef] = useStateRef("");
    const [preview, setPreview] = useState(false);

    const iframeRef = useRef(null);

    const url = useSelector(getUrl);
    const websiteData = useSelector(getWebsiteData);

    const simplificationRef = useRef([]);
    simplificationRef.current = simplifications;


    const dispatch = useDispatch();

    useEffect(() => {
        const iframe = iframeRef.current;
        const window = iframe.contentWindow;
        const idoc = iframe.contentDocument;
        if (!websiteData || !websiteData.simplifications) return;
        const newSimplifications = { ...websiteData }.simplifications.map(simplification => {
            return {
                node: getElementByXpath(simplification.xpath, idoc),
                active: true,
                validated: true,
                xpath: simplification.xpath,
                html: simplification.simplified,
                id: simplification.id
            }
        });
        console.log("SET SIMPLIFICATIONS", newSimplifications)
        // setSimplifications(_ => newSimplifications);
        // setSimplifications([{a: "COUCOU"}])
        setSimplifications([...newSimplifications])
        setTimeout(() => {
            onScroll();
        }, 500)

    }, [websiteData])

    // useEffect(() => {
    //     console.log("SIMPLIFICATION CHANGES", simplifications)
    // }, [simplifications])






    const onScroll = () => {
        const iframe = iframeRef.current;
        const window = iframe.contentWindow;
        const newsimplifications = [...simplificationRef.current]
            //  here we could alert for node being lost
            .filter(simplification => simplification.node)
            .map(simplification => {
                const top = simplification.node.getBoundingClientRect().top + simplification.node.getBoundingClientRect().height / 2;
                return { ...simplification, top }
            })

        setSimplifications(newsimplifications);
    }

    const addSimplification = (node) => {
        if (!node) return;

        const iframe = iframeRef.current;
        const window = iframe.contentWindow;
        const idoc = iframe.contentDocument;
        // keep only active simplifications
        const _simplifications = simplificationRef.current.filter(simplification => simplification.active == true && simplification.node);
        const newsimplification = {
            node,
            active: false, // default active to false
            validated: false, // default validated to false
            html: '', // default to empty html
            xpath: createXPathFromElement(node, idoc),
            id: uuid()
        }
        const newsimplifications = [..._simplifications, newsimplification].map(simplification => {
            if (!simplification || !simplification.node || !simplification.node.getBoundingClientRect) return null;
            const top = simplification.node.getBoundingClientRect().top + simplification.node.getBoundingClientRect().height / 2;
            return {
                ...simplification,
                top,
            }
        }).filter(simplification => simplification);

        setSimplifications(newsimplifications)
        setTimeout(() => {

            setSimplifications(newsimplifications)
        }, 100)

    }

    const onFrameLoad = (e, f) => {
        dispatch(fetchWebsiteData(url))

        const iframe = e.target;
        const idoc = iframe.contentDocument;
        const iwin = iframe.contentWindow
        const that = this;
        iwin.addEventListener('scroll', onScroll, true)
        iwin.onbeforeunload = function () {
            console.log('prevent leaving');
            return '';
        }
        const clearSelection = () => {
            console.log("CLEAR SELECTION")
            if (iwin.getSelection) {
                if (iwin.getSelection().empty) {  // Chrome
                    iwin.getSelection().empty();
                } else if (iwin.getSelection().removeAllRanges) {  // Firefox
                    iwin.getSelection().removeAllRanges();
                }
            } else if (document.selection) {  // IE?
                idoc.selection.empty();
            }

        }
        idoc.onmousedown = (e) => {
            clearSelection();
        }

        idoc.onmouseup = (e) => {
            console.log(e);
            e.preventDefault();
            e.stopPropagation();
            const range = idoc.createRange();
            const selection = iwin.getSelection();
            if (!selection.anchorNode) return;
            const node = selection.anchorNode.parentNode;
            range.selectNodeContents(node);
            addSimplification(node);
            clearSelection();
            selection.removeAllRanges()
            selection.addRange(range);
            console.log("RANGE", range)
        }
    }

    const setActive = (simplification, active) => {
        setSimplifications([...simplificationsRef.current].map(_simplification => {
            if (_simplification == simplification) {
                _simplification.active = active;
            }
            return _simplification;
        }));
    }

    const validateSimplification = (simplification) => {
        setSimplifications([...simplificationsRef.current].map(_simplification => {
            if (_simplification == simplification) {
                _simplification.validated = true;
            }
            return _simplification;
        }));
    }

    const removeSimplification = (simplification) => {
        setSimplifications([simplificationsRef.current].filter(_simplification => simplification != _simplification));

        const iframe = iframeRef.current;
        const iwin = iframe.contentWindow;
        iwin.getSelection().removeAllRanges()
    }

    const updateSimplification = (simplification, html) => {
        // TODO : SETSIMPLIFICATIONS ?
        console.log("COUCOU", simplification, simplifications, simplificationsRef.current)
        setSimplifications([...simplificationsRef.current].map(_simplification => {
            if (_simplification.xpath == simplification.xpath) {
                _simplification.html = html;
            }
            return _simplification;
        }))
        console.log("SS", simplificationsRef.current)
    }


    // const MyEditor = () => {
    //     const editor = useEditor({
    //       extensions: [
    //         StarterKit,
    //       ],
    //       content: `
    //         <p>
    //           Try to select <em>this text</em> to see what we call the bubble menu.
    //         </p>
    //         <p>
    //           Neat, isn’t it? Add an empty paragraph to see the floating menu.
    //         </p>
    //       `,
    //     })

    //     return (
    //       <>
    //         {editor && <BubbleMenu className="bubble-menu" tippyOptions={{ duration: 100 }} editor={editor}>
    //           <button
    //             onClick={() => editor.chain().focus().toggleBold().run()}
    //             className={editor.isActive('bold') ? 'is-active' : ''}
    //           >
    //             Bold
    //           </button>
    //           <button
    //             onClick={() => editor.chain().focus().toggleItalic().run()}
    //             className={editor.isActive('italic') ? 'is-active' : ''}
    //           >
    //             Italic
    //           </button>
    //           <button
    //             onClick={() => editor.chain().focus().toggleStrike().run()}
    //             className={editor.isActive('strike') ? 'is-active' : ''}
    //           >
    //             Strike
    //           </button>
    //         </BubbleMenu>}

    //         {editor && <FloatingMenu className="floating-menu" tippyOptions={{ duration: 100 }} editor={editor}>
    //           <button
    //             onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
    //             className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
    //           >
    //             H1
    //           </button>
    //           <button
    //             onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
    //             className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
    //           >
    //             H2
    //           </button>
    //           <button
    //             onClick={() => editor.chain().focus().toggleBulletList().run()}
    //             className={editor.isActive('bulletList') ? 'is-active' : ''}
    //           >
    //             Bullet List
    //           </button>
    //         </FloatingMenu>}

    //         <EditorContent editor={editor} />
    //       </>
    //     )
    //   }



    const Simplification = (simplification) => {
        if (!simplification || !simplification.node) return;
        const bb = simplification.node.getBoundingClientRect();
        const bbPadding = 5;
        const height = bb.height;

        return (
            <div style={{ top: simplification.top }} className='simplification'>
                {/* blue frame when validated */}
                <span style={{
                    pointerEvents: 'none',
                    marginTop: '10em',
                    display: simplification.validated == true ? 'block' : 'none',
                    position: 'fixed',
                    top: bb.top - bbPadding,
                    left: bb.left - bbPadding,
                    width: bb.width + bbPadding * 2,
                    height: bb.height + bbPadding * 2,
                    border: '3px solid #2E81EF'
                }}></span>
                {/* blue line when validated */}
                <span style={{
                    marginTop: '10em',
                    pointerEvents: 'none',
                    display: simplification.validated == true ? 'block' : 'none',
                    position: 'fixed',
                    top: bb.top + bb.height / 2,
                    left: bb.width + bb.left + 3,
                    width: iframeRef.current.offsetWidth - (bb.x + bb.width) + 30,
                    height: 0,
                    borderBottom: '3px solid #2E81EF'
                }}></span>
                <img
                    className="add"
                    style={{ display: simplification.validated == false ? 'block' : 'none' }}
                    onClick={() => { setActive(simplification, true) }} src={simplification.active ? "/images/ADDD.svg" : "/images/ADDD.svg"} />
                <span
                    className='simplificationContainer'
                    style={{
                        visibility: (simplification.active ? 'visible' : 'hidden'),
                        // height: height,
                        // bottom: -height / 2
                    }}>
                    <MyEditor
                        onChange={(html) => { console.log("EDITOR CHANGE", html); updateSimplification(simplification, html) }}
                        initialHtml={simplification.html}
                        placeholder="Entrez votre alternative textuelle simplifiée..."
                    />
                    <div
                        style={{ display: simplification.validated == false ? 'flex' : 'none' }}
                        className="actions">
                        <img src="/images/Ok.svg" onClick={() => { validateSimplification(simplification) }} />
                        <img src="/images/Nope.svg" onClick={() => { removeSimplification(simplification) }} />
                    </div>
                </span>
            </div>
        )
    }

    const togglePreview = () => {
        simplifications.forEach(simplification => {
            if (simplification.html) {
                simplification.node.innerHTML = simplification.html
            }
        })
        onScroll();
    }

    const _setEndActive = (active) => {
        setSimplifications([...simplificationsRef.current].map(simp => ({ ...simp, validated: simp.active ? true : false })));
        setEndActive(active);
    }

    const _submitWebsite = () => {
        dispatch(submitWebsite(url, simplifications, undefined, email));
    }

    const onEmailChange = (e) => {
        const _email = e.target.value;
        const isEmail = validEmail(_email);
        setEmailValid(isEmail);
        setEmail(_email);
        console.log("EMAIL CHANGE", _email)
    }


    const Buttons = () => {
        return (
            <div id="buttons">

                <div id="preview" style={{ visibility: endActive ? 'hidden' : 'visible' }} onClick={togglePreview} className={[preview ? 'active' : '', "button"].join(' ')}>
                    <img src="/images/APERCU.svg" />
                    <h3>Aperçu</h3>
                </div>

                <div id="end" onClick={() => { _setEndActive(true) }} className={["button", endActive ? 'active' : ''].join(' ')}>
                    <img src={endActive ? "/images/check.svg" : "/images/check_white.svg"} />
                    <h3>Terminer</h3>
                </div>
                {endActive ?
                    <div className="end">
                        <div className="row1">
                            <h3>Dernière étape :</h3>
                            <h3>validation et mise en ligne</h3>
                            <p>Transférez-nous votre proposition d’alternative textuelle. Notre équipe vous informera par e-mail de la validation et la mise en ligne de votre texte.</p>
                            <div className="input">
                                <input type="email" placeholder='Entrez votre email ici…' onChange={onEmailChange} value={email} required autoFocus />
                            </div>
                            <div onClick={_submitWebsite} className={["sendMail", emailValid ? 'active' : ''].join(' ')} src="/images/envoyer.png" >
                                <img src="/images/paper-plane-white.svg" alt="" />
                                <p>Transférer</p>
                            </div>
                        </div>
                        <div className="row2">
                            <img src="/images/submitbg.svg" />
                            <i className="material-icons close" onClick={() => { setEndActive(false) }}>close</i>
                        </div>
                    </div>
                    : ''}
                {endActive ?
                    <div className="blur" onClick={() => { setEndActive(false) }}></div> : ''}
            </div>
        )
    }


    const goHome = () => history.push('/')

    const Home = () => {
        const [hover, setHover] = useState(false);
        return (
            <div onClick={goHome} id="homeButton" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={hover && 'hover' || ''} >
                <img src={`/images/HOME${hover ? 'HOVER' : ''}.svg`} alt="" />
                <p className={hover && 'blue' || ''}>Accueil</p>
            </div>
        );
    }

    const EditInfo = () => {
        return (
            <div className="editinfo">
                <p>Français simplifié</p>
            </div>
        )
    }

    const UrlBar = () => {
        return (
            <div className="url">
                <div className="anchor"></div>
                <input type="url" placeholder='Entrer l’URL du site ici…' value={url} required />
            </div>

        )
    }

    const Header = () => {
        return (
            <div id="header">
                <Home />
                <EditInfo />
                <UrlBar />
                {Buttons()}
            </div>
        )
    }


    return (
        <div id="simplified">
            <Header />
            <iframe id="iframe" ref={iframeRef} onLoad={onFrameLoad} src={`/iframe?url=${url}`} />
            {/* <iframe id="iframe" ref={iframeRef} onLoad={onFrameLoad} src={`https://mairie14.paris.fr/pages/depistage-covid-19-gratuit-11364`} /> */}
            <div id="propositions">
                {simplifications.map(simplification => {
                    return Simplification(simplification);
                })}
            </div>
        </div>
    );

}

export default Simplified;
