import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

class ParagraphTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onChange(this.props.id, event.target.value);
    }

    render() {
        return (
            <div
                className="paragraphTitle">
                <TextareaAutosize 
                onChange={this.handleChange} 
                defaultValue={this.props.initialValue}
                placeholder={this.props.fromSidebar?"Titre de paragraphe":"Insérez le titre du paragraphe ici…"}></TextareaAutosize>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(ParagraphTitle);