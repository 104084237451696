import React, { Fragment, useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';

import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Types from './Falc/Types'
import Paragraph from './Falc/Paragraph'
import Title from './Falc/Title'
import SubTitle from './Falc/SubTitle'
import ParagraphTitle from './Falc/ParagraphTitle'
import uuid from 'uuid/v4';
import ImageUploader from './Falc/ImageUploader';
import { submitWebsite, fetchWebsiteData, setWebsiteData } from '../actions/storage-actions';
import { getUrl, getWebsiteData } from '../reducers/storage-reducer'
import history from '../history';
import useHover from './useHover';
const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;


const validEmail = (mail) => {
  return (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(mail))
}

const Falc = () => {

  const onChange = (id, data) => {
    setDocument(document => document.map(row => {
      if (row.component.props.id == id) {
        row.value = data;
      }
      return row;
    }))
  }




  const getItemOfType = (type, fromSidebar, initialValue, id, data) => {
    const _id = id || uuid();
    switch (type) {
      case Types.TITLE:
        return <Title onChange={onChange} initialValue={initialValue} fromSidebar={fromSidebar} id={_id} />
      case Types.SUBTITLE:
        return <SubTitle onChange={onChange} initialValue={initialValue} fromSidebar={fromSidebar} id={_id} />
      case Types.PARAGRAPH:
        return <Paragraph onChange={onChange} initialValue={initialValue} fromSidebar={fromSidebar} id={_id} />
      case Types.PARAGRAPH_TITLE:
        return <ParagraphTitle onChange={onChange} initialValue={initialValue} fromSidebar={fromSidebar} id={_id} />
      case Types.BLOCK_IMAGE:
        return <ImageUploader {...data} onImageResize={onImageResize} resizable={true} parentId={_id} initialImage={initialValue} onImageChange={onImageChange} initialValue={initialValue} isBig={true} fromSidebar={fromSidebar} id={id} />
      case Types.BIG_IMAGE:
        return <ImageUploader {...data} onSideImageDelete={onSideImageDelete} resizable={false} parentId={_id} initialImage={initialValue} onImageChange={onImageChange} initialValue={initialValue} isBig={true} fromSidebar={fromSidebar} id={id} />
      case Types.SMALL_IMAGE:
        return <ImageUploader {...data} onSideImageDelete={onSideImageDelete} resizable={false} parentId={_id} initialImage={initialValue} onImageChange={onImageChange} initialValue={initialValue} isBig={false} fromSidebar={fromSidebar} id={id} />
    }
  }


  const dispatch = useDispatch();
  const url = useSelector(getUrl);

  const websiteData = useSelector(getWebsiteData);
  const [preview, setPreview] = useState(false);
  const [endActive, setEndActive] = useState(false);
  const [emailValid, setEmailValid, emailValidRef] = useStateRef(false);
  const [email, setEmail, emailRef] = useStateRef("");
  const [selectedItem, setSelectedItem] = useState("");
  const [addItemMenuActive, setAddItemMenuActive] = useState(false);
  const [document, setDocument, docRef] = useStateRef([
    {
      id: uuid(),
      type: Types.TITLE,
      images: null,
      component: getItemOfType(Types.TITLE, false),
    }
  ])


  useEffect(() => {
    console.log("SELECTED ITEM CHANGED", selectedItem)
  }, [selectedItem])


  useEffect(() => {
    // TODO ERWAN uncomment
    dispatch(fetchWebsiteData(url));
  }, [])

  useEffect(() => {
    console.log("websiteData", websiteData)
    if (!websiteData || !websiteData.falc || websiteData.falc.length <= 0) return;


    setDocument(websiteData.falc.map(elem => {
      const id = uuid();
      const row = {
        id: id,
        value: elem.html,
        type: elem.type,
        images: elem.image
          // old system
          ? [getItemOfType(elem.image.size == "BIG" ? Types.BIG_IMAGE : Types.SMALL_IMAGE, false, elem.image.url, id, { ...elem, imageIndex: 0 })]
          : // new system
          (elem.images ? elem.images.map((image, index) => getItemOfType(image.size == "BIG" ? Types.BIG_IMAGE : Types.SMALL_IMAGE, false, image.url, id, { ...elem, imageIndex: index })) : []),
        images_datas: elem.image ? [elem.image] : elem.images,
        component: getItemOfType(elem.type, false, elem.html, id, elem)
      }
      delete row['image'];
      console.log("ROW ", row)
      return row;
    }));
  }, [websiteData])



  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };



  const onImageResize = (parentId, index, width, height) => {
    setDocument(() => [...docRef.current].map(row => {
      if (row.component.props.parentId == parentId) {
        console.log("ON IMAGE RESIZE == ", parentId, docRef.current, width, height)
        row.images_datas = [{ width, height }]
      }
      return row;
    }))
  }

  const onSideImageDelete = (parentId, imageIndex) => {
    setDocument(() => [...docRef.current].map(_row => {
      let row = { ..._row };
      if (row.id == parentId || row.component.props.id == parentId) {
        console.log("ON IMAGE SIDE DELETE 1== ", imageIndex, row.images, row.images_datas)
        row.images_datas = row.images_datas.filter((_, index) => index != imageIndex);
        row.images = row.images.filter((_, index) => index != imageIndex);
        console.log("ON IMAGE SIDE DELETE 2== ", imageIndex, row.images, row.images_datas)
      }
      return { ...row };
    }))
  }

  const onImageChange = (parentId, index, data) => {
    console.log("ON IMAGE CHANGE : TRYING TO SET IMAGE DATA", document, docRef.current, parentId)
    setDocument(() => [...docRef.current].map(row => {
      if (row.id == parentId || row.component.props.id == parentId) {
        console.log("ON IMAGE CHANGE == ", row, row.type, Types.BLOCK_IMAGE)
        if (row.type == Types.BLOCK_IMAGE) {
          row.value = data.url;
        } else {
          row.images_datas[index] = data;
        }
      }
      return row;
    }))
    console.log("onImageChange", document, index)
  }



  const renderComponentForDocument = (item) => {
    return item.component;
  }


  const togglePreview = () => {
    setPreview(!preview)
  }



  const onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case "document":
        console.log("INSERT DOUMENT")
        setDocument(reorder(document, source.index, destination.index));
        break;
      // case "sidebar":
      //   console.log("INSERT SIDEBAR")
      //   if (destination.droppableId == "document") {
      //     setSideBar(copy(SIDEBAR_ITEMS, document, source, destination))
      //   } else { // insert image
      //     console.log("INSERT IMAGE")
      //     setDocument(addImage(SIDEBAR_ITEMS, document, source, destination))
      //   }
      //   break;
      default:
        break;
    }
  }

  const deleteRow = (id) => {
    setDocument(() => [...docRef.current].filter(row => !(row.id == id || row.component.props.id == id)))
  }

  const DocumentCreator = () => {
    return (
      <Droppable droppableId="document">
        {(provided, snapshot) => {
          return (
            <div
              className={['document', snapshot.isDraggingOver ? 'dragging' : ''].join(' ')}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {docRef.current.map((item, index) => {
                const hasImage = item.images
                // const isBig = hasImage ? hasImage.props.isBig : null;
                const isBig = false;
                // console.log("== HAS IMAGE : ", item.type, Types.BLOCK_IMAGE, hasImage, isBig)

                return (
                  <Draggable id={item.id} key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <Fragment>
                        <div tabIndex={index + 1}
                          className={["element", snapshot.isDragging ? 'dragging' : '', hasImage ? 'hasImage' : '', isBig == true ? 'big' : '', isBig == false ? 'small' : ''].join(' ')}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={provided.draggableProps.style}
                        >


                          <Droppable droppableId={item.id}>
                            {(provided, snapshot) => {
                              return (
                                <div
                                  className={['insert-image', hasImage ? 'hasImage' : '', snapshot.isDraggingOver ? 'dragging' : ''].join(' ')}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {
                                    item.type != Types.BLOCK_IMAGE ?
                                      hasImage
                                        ?
                                        item.images
                                        :
                                        (
                                          item.images_datas
                                            ?
                                            item.images_datas.map(image_data => <img className={(image_data.size == "BIG" ? "big" : "small")} src={`${STORAGE_URL}/${image_data.url}`} />)
                                            :
                                            <div className="imagePlaceholder" ></div>
                                        )
                                      : null
                                  }
                                  {/* <Draggable id={item.id} key={item.id} draggableId={item.id} index={0}>
                                  {(provided, snapshot) => (
                                    <div className={[snapshot.isDragging ? 'dragging' : ''].join(' ')}
                                      ref={provided.innerRef}
                                      style={provided.draggableProps.style}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="custom-drag-handle-image" {...provided.dragHandleProps} tabIndex="-1"></div>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Draggable> */}
                                  {provided.placeholder}
                                </div>
                              )
                            }}
                          </Droppable>


                          <div className="itemWrapper" onClick={() => { setSelectedItem(item.id) }}>
                            {renderComponentForDocument(item)}
                            <div style={{ visibility: selectedItem == item.id ? 'visible' : 'hidden' }} className="custom-drag-handle" {...provided.dragHandleProps} tabIndex="-1"></div>
                            <div style={{ visibility: selectedItem == item.id ? 'visible' : 'hidden' }} onClick={() => deleteRow(item.id)} className="deleteRow" tabIndex="-1"></div>
                            {provided.placeholder}
                          </div>
                        </div>
                        {selectedItem == item.id && !preview && <AddItemMenu />}
                      </Fragment>
                    )}
                  </Draggable>

                )
              })}
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    );
  }

  const getSelectedDocumentIndex = () => {
    return docRef.current.map((elem, index) => elem.id == selectedItem ? index : null).find(_ => _ != null)
  }

  const AddItem = (type) => {
    const id = uuid();
    const newElement = {
      id: id,
      type: type,
      images: null,
      component: getItemOfType(type, false, undefined, id, null),
    };

    const documentIndex = getSelectedDocumentIndex();

    console.log("DOC INDEX", documentIndex)
    if (documentIndex != undefined) {
      const newDoc = [...docRef.current]
      newDoc.splice(documentIndex + 1, 0, newElement)
      setDocument(newDoc)
    } else {
      setDocument([...document, newElement]);
    }
    setSelectedItem(id);
    setAddItemMenuActive(false);
  }

  const AddImageAtPosition = (isBig) => {
    if (docRef.current.length <= 0) return;
    const _document = [...docRef.current]

    const documentIndex = getSelectedDocumentIndex();
    const prevImages = _document[documentIndex].images || [];
    const prevImagesDatas = _document[documentIndex].images_datas || [];
    const prevValues = _document[documentIndex].values || [];
    if (documentIndex != undefined) {
      _document[documentIndex].images = [...prevImages, <ImageUploader resizable={false} parentId={_document[documentIndex].id} onImageChange={onImageChange} imageIndex={prevImages.length} isBig={isBig} fromSidebar={false} id={uuid()} />];
      _document[documentIndex].images_datas = [...prevImagesDatas, { }]
      // _document[documentIndex].value = [...prevValues, "to_be_defined"]
    } else {
      _document[_document.length - 1].images = [...prevImages, <ImageUploader resizable={false} parentId={_document[_document.length - 1].id} onImageChange={onImageChange} imageIndex={prevImages.length} isBig={isBig} fromSidebar={false} id={uuid()} />];
      _document[_document.length - 1].images_datas = [...prevImagesDatas, { }]
      // _document[_document.length - 1].value = [...prevValues, "to_be_defined"]
    }
    setDocument(_document)
    setAddItemMenuActive(false);

  }


  useEffect(() => {
    console.log('DOCUMENT CHANGED', docRef.current);
  }, [document]);


  const AddItemMenu = () => {
    const [hover, setHover] = useState(false);

    return (
      <div id="addItem" style={{ padding: addItemMenuActive ? "2em" : "0" }}>
        <div
          className={`add ${(addItemMenuActive) ? 'active' : ''}`}
          onClick={() => { setAddItemMenuActive(!addItemMenuActive) }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        ></div>

        {addItemMenuActive ? <Fragment>
          {/* <div style={{ padding: '2em' }}> */}
          <div id="addText">
            <h4>Ajouter du texte</h4>
            <div className="option _title" onClick={() => AddItem(Types.TITLE)}>Titre</div>
            <div className="option _subtitle" onClick={() => AddItem(Types.SUBTITLE)}>Sous-titre</div>
            <div className="option _paragraph" onClick={() => AddItem(Types.PARAGRAPH)}>Paragraphe</div>
          </div>
          <div id="addPicture">
            <h4>Ajouter un visuel</h4>
            <div className="options">
              <div className="option">
                <h5>Image à gauche</h5>
                <img src="/images/IMGGAUCHE.svg" alt="" onClick={() => AddImageAtPosition(true)} />
              </div>
              <div className="option">
                <h5>Image au centre</h5>
                <img src="/images/IMG CENTRE.svg" alt="" onClick={() => AddItem(Types.BLOCK_IMAGE)} />
              </div>
              <div className="option">
                <h5>Pictogramme</h5>
                <img src="/images/PICTO.svg" alt="" onClick={() => AddImageAtPosition(false)} />
              </div>
            </div>

          </div>
          {/* </div> */}
        </Fragment> : null
        }
      </div >
    );
  }



  const _submitWebsite = () => {
    // TODO ERWAN uncomment
    dispatch(submitWebsite(url, undefined, docRef.current, emailRef.current));
  }

  const onEmailChange = (e) => {
    const _email = e.target.value;
    const isEmail = validEmail(_email);
    setEmailValid(isEmail);
    setEmail(_email);
    console.log("EMAIL CHANGE", _email)
  }
  const Buttons = (() => {

    const [hoverRef, isHovered] = useHover();

    return (
      <div id="buttons">
        <div id="preview" ref={hoverRef} style={{ visibility: endActive ? 'hidden' : 'visible' }} onClick={togglePreview} className={[preview ? 'active' : '', "button"].join(' ')}>
          <img src="/images/APERCU.svg" />
          <h3>Aperçu</h3>
        </div>

        <div id="end" onClick={() => { console.log("DOCREF", docRef.current); setEndActive(true) }} className={["button", endActive ? 'active' : ''].join(' ')}>
          <img src={endActive ? "/images/check.svg" : "/images/check_white.svg"} />
          <h3>Terminer</h3>
        </div>

        {endActive ?
          <div className="end">
            <div className="row1">
              <h3>Dernière étape :</h3>
              <h3>validation et mise en ligne</h3>
              <p>Transférez-nous votre proposition d’alternative textuelle. Notre équipe vous informera par e-mail de la validation et la mise en ligne de votre texte.</p>
              <div className="input">
                <input autoFocus type="email" placeholder='Entrez votre email ici…' onChange={onEmailChange} value={email} required />
              </div>
              <div onClick={_submitWebsite} className={["sendMail", emailValidRef.current ? 'active' : ''].join(' ')} src="/images/envoyer.png" >
                <img src="/images/paper-plane-white.svg" alt="" />
                <p>Transférer</p>
              </div>
            </div>
            <div className="row2">
              <img src="/images/submitbg.svg" />
              <i className="material-icons close" onClick={() => { setEndActive(false) }}>close</i>
            </div>
          </div>
          : ''}
        {endActive ?
          <div className="blur" onClick={() => { setEndActive(false) }}></div> : ''}

      </div>
    )
  })

  const goHome = () => history.push('/')

  const Home = () => {
    const [hover, setHover] = useState(false);
    return (
      <div onClick={goHome} id="homeButton" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={hover && 'hover' || ''} >
        <img src={`/images/HOME${hover ? 'HOVER' : ''}.svg`} alt="" />
        <p className={hover && 'blue' || ''}>Accueil</p>
      </div>
    );
  }

  const EditInfo = () => {
    return (
      <div className="editinfo">
        <p>Français très simplifié</p>
      </div>
    )
  }

  const UrlBar = () => {
    return (
      <div className="url">
        <div className="anchor"></div>
        <input type="url" placeholder='Entrer l’URL du site ici…' value={url} required />
      </div>

    )
  }

  const Header = () => {
    return (
      <div id="header">
        <Home />
        <EditInfo />
        <UrlBar />
        {Buttons()}
      </div>
    )
  }

  return (
    <div id="falcBuilder" className={preview ? 'preview' : ''}>
      <Header />
      <DragDropContext onDragEnd={onDragEnd}>
        {DocumentCreator()}
        {/* {SideBar()} */}
      </DragDropContext>
    </div>
  );

}

export default Falc
