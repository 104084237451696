import axios from 'axios';
import Types from '../components/Falc/Types';
import history from '../history';
export const SET_LANGUAGE_LEVEL = 'storage:SET_LANGUAGE_LEVEL';
export const SET_URL = 'storage:SET_URL';
export const SET_WEBSITE_DATA = 'storage:SET_WEBSITE_DATA';

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

export function setLanguageLevel(data) {
  return {
    type: SET_LANGUAGE_LEVEL,
    payload: data
  };
}

export function setUrl(data) {
  return {
    type: SET_URL,
    payload: data
  };
}

export function setWebsiteData(data) {
  return {
    type: SET_WEBSITE_DATA,
    payload: data
  };
}

export function submitWebsite(url, simplifications, falc, email) {
  if (simplifications) {
    simplifications = simplifications.map(simplification => ({
      id: simplification.id,
      xpath: simplification.xpath,
      simplified: simplification.html,
    }))
  }

  if (falc) {
    console.log("SUBMIT", falc)
    falc = falc.map(block => {
      let _block = {
        type: block.type,
        html: block.value,
      }
      console.log("SUBMIT BLOCK", block.value)
      if (block.type == Types.BLOCK_IMAGE) {
        _block.images = {
          width: (Array.isArray(block.images_datas) ? block.images_datas[0].width : block.images_datas.width) || 200 ,
          height: (Array.isArray(block.images_datas) ? block.images_datas[0].height : block.images_datas.height) || 200
        }
      } 
      else if (block.images_datas) {
        _block.images = block.images_datas
      }
      return _block;
    })
    console.log("SUBMIT", falc)
  }

  var location = document.createElement('a');
  location.href = url;
  const trimmed_url = [location.host, location.pathname].join('')

  return (dispatch, getState) => {
    axios.post(`${STORAGE_URL}/website`, {
      matching_urls: [url, trimmed_url],
      simplifications,
      falc,
      email
    })
      .then((resp) => resp.data)
      .then((data) => {
        console.log("DATA", data)
        history.push('/thankyou');
      })
  };
}

export function fetchWebsiteData(url) {
  return (dispatch, getState) => {
    axios.post(`${STORAGE_URL}/website/url`, { url })
      .then((resp) => resp.data)
      .then((data) => {
        console.log("FETCH SIMPLIFICATIONS", data)
        return dispatch(setWebsiteData(data));
      })
  };
}
