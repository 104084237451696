import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../history';

class ThankYou extends Component {

    constructor(props) {
        super(props);
        this.goHome = this.goHome.bind(this);
    }

    goHome() {
        history.push('/');
    }

    render() {
        return (
            <div id="thankyou">
                <div className="text">
                    <h2>Merci de votre contribution !</h2>
                    <p>Bientôt, 7 millions de francophones en plus<br />pourront accéder sereinement à votre site.</p>
                    <p>Et maintenant, on veut tout savoir…</p>
                    <p> Racontez-nous votre expérience de l’outil à : <br /><strong>bonjour@accessibilis.fr</strong></p>
                </div>
                <div className="img">
                    <img src="/images/accessibility.svg" />
                </div>
                <div id="goback" onClick={this.goHome}>
                    <img src="/images/left-arrow.svg"/>
                    <p>Revenir à l’accueil</p>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
    }
}

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(ThankYou)
