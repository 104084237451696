import React, { Component, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import history from '../history';
import { setLanguageLevel, setUrl } from '../actions/storage-actions';
import { getLanguageLevel, getUrl } from '../reducers/storage-reducer';


const validURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

const Home = (props) => {
  console.log("PROPS", props)
  const [step, setStep] = useState(1);
  const [urlValide, setUrlValide] = useState(false);
  const dispatch = useDispatch();
  const languageLevel = useSelector(getLanguageLevel);
  const url = useSelector(getUrl)

  const onChange = (e) => {
    const url = e.target.value;
    const isUrl = validURL(url);
    console.log("TEST", isUrl, url)
    setStep(isUrl ? (languageLevel ? 3 : 2) : 1)
    dispatch(setUrl(url))
    localStorage.setItem('url', url)
  }

  const _setLanguageLevel = (ll) => {
    dispatch(setLanguageLevel(ll));
    setStep(3);
  }

  const start = () => {
    console.log("START", languageLevel)
    switch (languageLevel) {
      case "falc":
        return history.push('/falc')
      case "simplified":
        return history.push('/simplified')
    }
  }

  return (
    <div id="home">
      <h2 id="welcome" className="title2">Avec <span className="logo blue">U31</span>, proposez plusieurs alternatives de vos contenus à vos lecteurs.</h2>


      <div className="wrapper">
        <p className="info">Quelle page internet voulez-vous transcrire ?</p>
        <div id="step1" className={step >= 1 ? 'active' : ''}>
          <div className="input">
            <div className="anchor"></div>
            <input type="url" placeholder='Entrer l’URL du site ici…' onChange={onChange} value={url} required />
          </div>
        </div>

        <p className="info">Sélectionnez le niveau de langage dans lequel vous souhaitez transcrire le texte.</p>
        <div id="step2" className="active">
          <div className="option" onClick={() => { _setLanguageLevel('simplified') }}>
            <input style={{ backgroundColor: "#7ABDFD" }} type="radio" name="languageLevel" value="simplified" checked={languageLevel == "simplified"} />
            <label className="label" for="simplified" style={{ color: "#7ABDFD" }}>Langage clair</label>
            <p className="description">Utilisez notre outil pour créer une version en langage clair pour chaque élément textuel de votre page internet.</p>
          </div>
          <div className="spacer"></div>
          <div className="option" onClick={() => { _setLanguageLevel('falc') }}>
            <input type="radio" name="languageLevel" value="falc" checked={languageLevel == "falc"} />
            <label className="label" for="simplified" style={{ color: "#FEA160" }}>Langage très simplifié</label>
            <p className="description">Rédigez une version en français très simplifié de votre page internet en utilisant notre modèle type conforme aux normes d’accessibilité.</p>
          </div>
        </div>
        <div id="step3" className={step >= 3 ? 'active' : ''}>
          <button onClick={start}>Commencer</button>
        </div>
      </div>

    </div>
  );
}

export default Home;
