const Types = {
    DIVIDER: 'divider',

    PARAGRAPH: 'paragraph',
    TITLE: 'title',
    SUBTITLE: 'subtitle',
    PARAGRAPH_TITLE: 'paragraph_title',
    SMALL_IMAGE: 'small_image',
    BIG_IMAGE: 'big_image',
    BLOCK_IMAGE: 'block_image'
}

export default Types;