import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import MyEditor from './Editor/Editor';

class Paragraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value) {
        console.log('EDITOR CHANGE : ', value)
        this.props.onChange(this.props.id, value);
    }

    render() {
        return (
            <div className="paragraph">
                <MyEditor
                    onChange={this.handleChange}
                    initialHtml={this.props.fromSidebar ? "Je suis un paragraphe.<br/><br/> Cliquez ici pour ajouter votre propre texte. <br/><br/>C’est facile." : this.props.initialValue}
                    placeholder={this.props.fromSidebar ? null : "Insérer le texte du paragraphe ici..."}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(Paragraph);