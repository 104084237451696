import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

class SubTitle extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onChange(this.props.id, event.target.value);
    }

    render() {
        return (
            <div className="subtitle">
                <TextareaAutosize
                    onChange={this.handleChange}
                    defaultValue={this.props.initialValue}
                    placeholder={this.props.fromSidebar ? "Sous-titre" : "Insérez le sous-titre ici…"}></TextareaAutosize>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(SubTitle);