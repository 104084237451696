import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import uuid from 'uuid/v4';

const Title = ({ onChange, id, initialValue, fromSidebar }) => {

    // const [value, setValue] = useState("");

    return (
        <div
            className="title">
            <TextareaAutosize
                onChange={(e) => { onChange(id, e.target.value) }}
                // onChange={(e) => onChange(id, e.target.value)}
                // value={value}
                defaultValue={initialValue}
                placeholder={fromSidebar ? "Titre" : "Insérez le titre ici…"}>

            </TextareaAutosize>
        </div>
    );
}

export default Title;