import React from 'react'
import {
  useEditor,
  EditorContent,
  BubbleMenu,
  FloatingMenu,
} from '@tiptap/react'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import StarterKit from '@tiptap/starter-kit'
// import './styles.scss'

export default ({ onChange, initialHtml, placeholder }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
      Underline,
    ],
    content: initialHtml || ``,
    placeholder: placeholder,
    onUpdate: ({ editor }) => onChange(editor.getHTML()),
  })

  const setLink = () => {
    const url = window.prompt('URL')

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run()
  }

  const unsetLink = () => {
    editor.chain().focus().unsetLink().run()
  }

  return (
    <>
      {editor && <BubbleMenu className="bubble-menu" tippyOptions={{ duration: 100 }} editor={editor}>
        <img
          src="/images/toolbar/bold.svg"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        />
        <img
          src="/images/toolbar/italic.svg"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        />
        <img
          src="/images/toolbar/underline.svg"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={editor.isActive('underline') ? 'is-active' : ''}
        />
        <img
          src="/images/toolbar/link.svg"
          onClick={() => editor.isActive('link')?unsetLink():setLink()}
          className={editor.isActive('link') ? 'is-active' : ''}
        />
      </BubbleMenu >}

      {
        editor && <FloatingMenu className="floating-menu" tippyOptions={{ duration: 100 }} editor={editor}>


          <img
            src="/images/toolbar/title.svg"
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
          />
          <img
            src="/images/toolbar/subtitle.svg"
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
          />

          <img
            src="/images/toolbar/bullet_list.svg"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive('bulletList') ? 'is-active' : ''}
          />
        </FloatingMenu>
      }

      <EditorContent editor={editor} />
    </>
  )
}