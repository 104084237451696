import {
  SET_LANGUAGE_LEVEL,
  SET_URL,
  SET_WEBSITE_DATA
} from '../actions/storage-actions';

export default function storageReducer(state='', { type, payload }) {
  switch (type) {
    case SET_LANGUAGE_LEVEL:
    return {
      ...state,
      languageLevel: payload
    }
    case SET_URL:
    return {
      ...state,
      url: payload
    }
    case SET_WEBSITE_DATA:
    return {
      ...state,
      websiteData: payload
    }
    default:
    return state;
  }
}

// selectors
export function getLanguageLevel(state) {
  return state.storageReducer.languageLevel;
}

export function getUrl(state) {
  return state.storageReducer.url;
}

export function getWebsiteData(state) {
  return state.storageReducer.websiteData;
}
