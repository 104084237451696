import { createHashHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import Falc from './components/Falc';
import Simplified from './components/Simplified';
import Home from './components/Home';
import storageReducer from './reducers/storage-reducer';
import './css/index.css';
import history from './history';
import ThankYou from './components/ThankYou';

const allReducers = combineReducers({
  storageReducer: storageReducer
});

const allStoreEnhancers = composeWithDevTools(
  applyMiddleware(thunk) ,
);

const store = createStore(
  allReducers,
  {
    storageReducer: {
      languageLevel: null,
      // url: 'https://mairie14.paris.fr/pages/depistage-covid-19-gratuit-11364',
      url: localStorage.url || '',
      websiteData: {}
    }
  },
  allStoreEnhancers
);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route exact path = "/" component={Home} />
        <Route path="/falc" component={Falc} />
        <Route path="/simplified" component={Simplified} />
        <Route path="/thankyou" component={ThankYou} />
      </Switch>
    </Router>
  </Provider>
  , document.getElementById('root')
);
